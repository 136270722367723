import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button, Paragraph } from "@nn-design-system/react-component-library";
import CustomInput from "../../CustomInputs/CustomInput";
import { checkLength } from "../../../Utils/CheckLength";
import useFetch from "../../../CustomHooks/useFetch";

const BankIDForm = () => {
  const [personalIdentifier, setPersonalIdentifier] = useState("");
  const [validationText, setValidationText] = useState("");

  const {
    sendRequest: requestBankIdLogin,
    data: dataBankIdLogin,
    error: errorBankIdLogin,
  } = useFetch();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !personalIdentifier ||
      (personalIdentifier.replace(/[\s/]+/g, "").length !== 10 &&
        personalIdentifier.replace(/[\s/]+/g, "").length !== 9)
    ) {
      setValidationText("Rodné číslo musí mít 9 či 10 znaků");
    } else {
      setValidationText("");
      requestBankIdLogin({
        method: 'GET',
        url: `${process.env.REACT_APP_BaseURL}${process.env.REACT_APP_BANK_ID_LOGIN}/${personalIdentifier}`,
        headers: {
          'Custom-Header': 'YourHeaderValue'
        },
        withCredentials: true,
      });
    }
  };

  React.useEffect(() => {
    if (dataBankIdLogin) {
      window.location.href = dataBankIdLogin.url;
    } else if (errorBankIdLogin) {
      setValidationText("Nastala chyba při přesměrování");
    }
  }, [dataBankIdLogin, errorBankIdLogin]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "1rem",
      }}
    >
      <form onSubmit={handleFormSubmit}>
        <CustomInput
          labelText="Rodné číslo"
          placeholderText="Zadejte rodné číslo bez lomítka"
          value={personalIdentifier}
          onChange={(e) => {
            setPersonalIdentifier(checkLength(e.target.value, 10));
          }}
          validationText={validationText}
        />
        <Button mt="1rem" type="Submit" variant={"PrimaryMedium"}>
          Přihlásit přes Bank ID
        </Button>
      </form>
    </Box>
  );
};

export default BankIDForm;
