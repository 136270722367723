import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import { Select } from "@nn-design-system/react-component-library";
import SingleLineContainer from "../../../../../../../Components/ReportFormTemplate/SingleLineContainer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const TypeOfInjuriesForSeriuseDieses: React.FC = () => {
   const fieldNameLowerCase = "typeOfInjuriesForSeriouseDieses";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   return (
      <SingleLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <div ref={ref}>
            <Select
               labelText="Vyberte diagnózu"
               placeholderText="Vyberte diagnózu"
               value={fieldValue ?? undefined}
               onChange={(e: any) => {
                  dispatch(
                     updateFirstStepGeneralFields({
                        value: e.target.value,
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               options={[
                  { text: "Rakovina", value: 0 },
                  { text: "Infarkt", value: 1 },
                  { text: "Roztroušená skleróza", value: 2 },
                  { text: "Mrtvice", value: 3 },
                  { text: "Mám závažné zdravotní následky", value: 4 },
                  { text: "Vybraná onemocnění dětí", value: 6 },
                  { text: "Závislost dítěte na péči jiné osoby", value: 7 },
                  { text: "Jiná diagnostika", value: 5 },
               ]}
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
            />
         </div>
      </SingleLineContainer>
   );
};

export default TypeOfInjuriesForSeriuseDieses;
